import 'vue/jsx'

// 引入windi css
import '@/plugins/unocss'

// 导入全局的svg图标
import '@/plugins/svgIcon'

// 初始化多语言
import { setupI18n } from '@/plugins/vueI18n'

// 引入状态管理
import { setupStore } from '@/store'

// 全局组件
import { setupGlobCom } from '@/components'

// 引入element-plus
import { setupElementPlus } from '@/plugins/elementPlus'

// 引入全局样式
import '@/styles/index.less'

// 引入动画
import '@/plugins/animate.css'

// 路由
import { setupRouter } from './router'

// 权限
import { setupPermission } from './directives'

// 地图
import 'vue3-openlayers/styles.css'
import OpenLayersMap from 'vue3-openlayers'

import { createApp } from 'vue'

import App from './App.vue'

import './permission'

// 动态引入监控脚本
function loadMonitoringScript(userId) {
  const el = document.createElement('script')
  el.src = 'https://jk.zjagri.cn/monitoring.js'
  el.id = 'xcdn_monitoring'

  const option = {
    siteId: '117', // 请替换为实际站点编号
    appId: '7477849188645723', // 请替换为实际应用编码
    userId: userId || '0', // 初始为 '0'，或空字符串，登录后再更新
    sourceType: 'PC',
    regionCode: '330723', // 替换为你的地区编码
    regionName: '武义县' // 替换为你的地区名称
  }

  el.setAttribute('option', JSON.stringify(option))
  document.body.appendChild(el)
}

// 创建实例
const setupAll = async () => {
  const app = createApp(App)

  await setupI18n(app)

  setupStore(app)

  setupGlobCom(app)

  setupElementPlus(app)

  setupRouter(app)

  setupPermission(app)

  app.use(OpenLayersMap /*, options */)

  // 调用动态加载监控脚本，初始 userId 为 '0'
  // 仅在开发环境加载监控脚本
  if (import.meta.env.MODE !== 'production') {
    loadMonitoringScript('0')
  }

  app.mount('#app')
}

// 当用户登录成功时，重新加载监控脚本并传递正确的 userId
function onUserLoginSuccess(userId) {
  const oldScriptTag = document.getElementById('xcdn_monitoring')
  if (oldScriptTag) {
    oldScriptTag.remove()
  }
  loadMonitoringScript(userId) // 登录成功后，传递新的 userId
}

setupAll()

export { onUserLoginSuccess } // 导出函数，供登录逻辑使用
